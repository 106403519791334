@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  background: linear-gradient(
    259deg,
    #1f1c53 -23.4%,
    #16163b 46.16%,
    #1f1c53 130.13%
  );
  height: 100%;
}

/* @font-face {
  font-family: Nunito-Bold;
  src: url("./assets/fontfamily/Nunito-Bold.ttf");
}

@font-face {
  font-family: Nunito-SemiBold;
  src: url("./assets/fontfamily/Nunito-SemiBold.ttf");
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url("./assets/fontfamily/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("./assets/fontfamily/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins-Regular;
  src: url("./assets/fontfamily/Poppins-Regular.ttf");
}

@font-face {
  font-family: Roboto-Bold;
  src: url("./assets/fontfamily/Roboto-Bold.ttf");
}

@font-face {
  font-family: Roboto-Medium;
  src: url("./assets/fontfamily/Roboto-Medium.ttf");
}

@font-face {
  font-family: Roboto-Regular;
  src: url("./assets/fontfamily/Roboto-Regular.ttf");
}

.f-f-b-n {
  font-family: Nunito-Bold;
}

.f-f-sm-n {
  font-family: Nunito-SemiBold;
}

.f-f-sm-p {
  font-family: Poppins-SemiBold;
}

.f-f-m-p {
  font-family: Poppins-Medium;
}

.f-f-r-p {
  font-family: Poppins-Regular;
}

.f-f-b-r {
  font-family: Roboto-Bold;
}

.f-f-m-r {
  font-family: Roboto-Medium;
}

.f-f-r-r {
  font-family: Roboto-Regular;
}
.f-f-sm-r {
  font-family: Roboto-Bold;
} */
