.table-bg {
  background: linear-gradient(
    259deg,
    #1f1c53 -23.4%,
    #16163b 46.16%,
    #1f1c53 130.13%
  );
}
.dropdown-makker::marker {
  display: none !important;
}

.box-set {
  background: linear-gradient(68deg, #131433 16.1%, #1f1c53 79.38%);
}
.crd1 {
  border-radius: 15px;
  border: 1px solid var(--border-2, #30365b);
  background: linear-gradient(267deg, #1a174b -59.22%, #121332 98.08%);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.crd2 {
  border-radius: 15px;
  border: 1px solid var(--border-2, #30365b);
  background: linear-gradient(
    86deg,
    rgba(20, 24, 45, 0.46) 2.54%,
    rgba(36, 31, 106, 0.46) 131.26%
  );
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.crd3 {
  border-radius: 15px;
  border: 1px solid var(--border-2, #30365b);
  background: linear-gradient(267deg, #1a174b -59.22%, #121332 98.08%);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.crd4 {
  border-radius: 15px;
  border: 1px solid var(--border-2, #30365b);
  background: linear-gradient(85deg, #131433 0.94%, #1f1c53 97.26%);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.numbertankscrd {
  border-radius: 15px;
  border: 1px solid var(--border-2, #30365b);
  background: linear-gradient(248deg, #131433 41.1%, #1f1c53 125.6%);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.ordercrd {
  border-radius: 15px;
  border: 1px solid var(--border-2, #30365b);
  background: linear-gradient(68deg, #131433 16.1%, #1f1c53 79.38%);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.ordercrd-2 {
  border-radius: 15px;
  border: 1px solid var(--border-2, #30365b);
  background: linear-gradient(258deg, #131433 38.77%, #1f1c53 95.06%);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.ordercrd-3 {
  border-radius: 15px;
  border: 1px solid var(--border-2, #30365b);
  background: linear-gradient(256deg, #131433 19.75%, #1f1c53 94.56%);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.ordercrd-4 {
  border-radius: 15px;
  border: 1px solid var(--border-2, #30365b);
  background: linear-gradient(251deg, #131433 38.63%, #1f1c53 78.52%);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.signin-bg {
  background-image: url("../src/assets/images/signinbackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.signincrd {
  border-radius: 15px;
  opacity: 0.66;
  background: linear-gradient(
    212deg,
    #1a1946 1.66%,
    #17173c 50.37%,
    #1a1946 100%
  );
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.09);
}

.login-crd {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border: 1px solid var(--border-2, #30365b);
}

.createdlg {
  border-radius: 15px;
  border: 1px solid var(--border-2, #30365b);
  background: linear-gradient(249deg, #131433 -2.38%, #1e1b53 120.6%);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.createdlg-cylinder {
  border: 1px solid var(--border-2, #30365b);
  background: linear-gradient(249deg, #131433 -2.38%, #1e1b53 120.6%);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.availbalnce {
  background-image: url("./assets/images/earningcrd.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 15px;
  background-position: center;
}

.box-set2 {
  border-radius: 15px;
  border: 1px solid var(--border-2, #30365b);
  background: linear-gradient(249deg, #131433 -2.38%, #1e1b53 120.6%);

  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.withdraw-set {
  background: url("./assets/images/Group 239601.png");
  background-repeat: no-repeat;
  background-size: cover;

  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.custom-select {
  -webkit-appearance: none;
  background-image: url("./assets//images//downarrow.svg");
  background-repeat: no-repeat;
  background-position: right 12px center;
}

.custom-select::-ms-expand {
  display: none; /* Remove default arrow in Internet Explorer */
}

/* Custom scrollbar styles for WebKit browsers */
.custom-scrollbar {
  scrollbar-width: 1px !important; /* For Firefox */
  scrollbar-color: #30365b transparent; /* For Firefox */
}
